// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/app/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---cache-gatsby-mdx-mdx-wrappers-dir-29878-c-7-e-81-ec-4734-fa-0-e-71-b-4-caf-91-bab-scope-hash-3010-b-3-badc-54-a-9-dfa-4-a-4-c-80-e-419-a-41-b-2-js": () => import("/app/.cache/gatsby-mdx/mdx-wrappers-dir/29878c7e81ec4734fa0e71b4caf91bab--scope-hash-3010b3badc54a9dfa4a4c80e419a41b2.js" /* webpackChunkName: "component---cache-gatsby-mdx-mdx-wrappers-dir-29878-c-7-e-81-ec-4734-fa-0-e-71-b-4-caf-91-bab-scope-hash-3010-b-3-badc-54-a-9-dfa-4-a-4-c-80-e-419-a-41-b-2-js" */),
  "component---src-pages-index-js": () => import("/app/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-404-js": () => import("/app/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("/app/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-over-daans-js": () => import("/app/src/pages/over-daans.js" /* webpackChunkName: "component---src-pages-over-daans-js" */),
  "component---src-pages-werkwijze-js": () => import("/app/src/pages/werkwijze.js" /* webpackChunkName: "component---src-pages-werkwijze-js" */)
}

